@mixin smartphone {
  @media (max-width: 599px) { @content; }
}
@mixin tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin note_desktop {
  @media (min-width: 1200px) { @content; }
}
@mixin big-desktop {
  @media (min-width: 1800px) { @content; }
}