@mixin flex ($align-item:false, $align-content:false, $justify-item:false, $justify-content:false, $wrap:false, $flex-direction:false) {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  @if $wrap {flex-wrap: $wrap; -webkit-flex-wrap: $wrap; -ms-flex-wrap: $wrap;}
  @if $align-item { align-items: $align-item; -webkit-align-items:$align-item;}
  @if $align-content { align-items: $align-content; -webkit-align-items:$align-content;}
  @if $justify-item { justify-items: $justify-item;}
  //-webkit-justify-items:$justify-item;
  @if $justify-content { justify-content: $justify-content; -webkit-justify-content:$justify-content;}
  @if $flex-direction { flex-direction: $flex-direction; -webkit-flex-direction:$flex-direction; -ms-flex-direction: $flex-direction;}
  }

  @mixin transition($values) {
    -webkit-transition: $values;
    -moz-transition: $values;
    //-ms-transition: $values;
    -o-transition: $values;
    transition: $values;
    }

    .row-flex {
      @include flex($justify-content:center);
    }