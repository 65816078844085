@import "modules/all";

html {
  scroll-behavior: smooth;
}
body {
  font-family: 'Open Sans', sans-serif;
  line-height: 1.5;
  font-size: 16px;
}

@keyframes animation-arrow {
  0%   { bottom: 30px; }
  100% { bottom: 50px; }
}

// typography
.ldd-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2em;
  font-weight: 800;
  text-align: center;
  @include note_desktop {
    font-size: 2em;
    margin-bottom: 20px;
    .break {
      display: none;
    }
  }
}
.ldd-paragraph {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.8em;
  font-weight: 400;
  color: $c-black-lead;
  margin-top: 15px;
  @include note_desktop {
    font-size: 1em;
  }
}

// Button
.ldd-button-container {
  @include flex($justify-content:center, $align-item:center);
  margin-top: 40px;
}
.ldd-btn {
    font-size: 0.7em;
    font-weight: 700;
    color: $c-white;
    text-transform: uppercase;
    padding: 13px 15px;
    outline: none;
    box-shadow: none;
    border: none;
    border-radius: 4px;
}
.ldd-btn-a {
  background-color: orange;
  transition: 400ms;
  color: $c-black-lead;
  &:hover {
    background-color: orange;
    transition: 400ms;
    color: $c-black-lead;
    transform: scale(1.05);
    box-shadow: 0 2px 8px -4px #000;
  }
  
}
.ldd-btn-b {
  background-color: $c-red;
  border: 1px solid $c-white;
  color: $c-white;
  @include note_desktop {
    background-color: $c-white;
    border: 1px solid $c-red;
    transition: 400ms;
    color: $c-red;
  }
  &:hover {
    background-color: $c-red;
    border: 1px solid $c-white;
    color: $c-white;
  }
}

// Header 
#ldd-header {
  @include flex($justify-content:space-between, $align-item:center);
  background-color: $c-red;
  padding: 0 15px;
  @include note_desktop {
    height: 10vh;
  }
  .ldd-logo {
    text-align: center;
    @include note_desktop {
      text-align: left;
      margin-left: 30px;
    }
    .mjv-logo {
      font-size: 4rem;
      color: $c-white;
    }
  }
}
.ldd-banner {
  width: 100%;
  height: auto;
  @include note_desktop {
    @include flex($justify-content:flex-start);
    position: relative;
    background-color: $c-red;
    height: 100vh;
    &:after {
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      background-image: url("../img/mjv_design_driven_ds_onda.png");
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: cover;
      background-position-y: 18rem;
      position: absolute;
     }
  }
 .ldd-banner-title {
    font-size: 1.8em;
    font-weight: 800;
    color: $c-white;
    text-align: center;
    line-height: 1.4;
    background-color: $c-red;
    @include note_desktop {
      display: none;
    }
 } 
 .ldd-figure-book {
    padding-top: 20px;
    background-image: url("../img/mjv_design_driven_ds_onda.png");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 9rem;
    background-color: $c-red;
    @include note_desktop {
      background-image: none;
      width: 50%;
    }
    img {
      width: 90%;
      left: 10%;
      @include note_desktop {
        width: 100%;
        left: 5%;
        z-index: 1501;
      }
    }
 }
 .icon{
   display: none;
   @include note_desktop {
    @include flex($justify-content:center);
    position: absolute;
    bottom: 30px;
    width: 100%;
    z-index: 1502;
    animation: animation-arrow 800ms infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    cursor: pointer;
    a {
      i {
        font-size: 1.5em;
        color: $c-red;
      }
    }
   }
 }
}
.ldd-main {
  margin-top: 40px;
  padding: 0 30px;
  @include note_desktop {
    margin-top: 0;
  }
  .ldd-paragraph {
    @include note_desktop {
      font-size: 0.9em;
      color: $c-white;
    }
  }
}
.ldd-nav-lang {
  margin-top: 30px;
  margin-right: 30px;
  .ldd-lang-item {
    width: 40px;
    height: 50px;
  }
}

// Header Reponsive Divs
.ldd-banner-title-reponsive {
  display: none;
  @include note_desktop {
    display: block;
    font-size: 2.2em;
    font-weight: 800;
    color: $c-white;
  }
}
.ldd-banner-responsive {
  @include note_desktop {
    @include flex($flex-direction:column);
    width: 40%;
    z-index: 1501;
    .ldd-button-container {
      order: 1;
    }
  }
}

// Section 1 - 
.ldd-about-book {
  padding: 0 30px;
  @include note_desktop {
    padding: 30px;
  }
  .ldd-mockup {
    @include flex($justify-content:center, $align-item:center);
    margin: 30px 0 40px 0;
    @include note_desktop {
      margin: 0;
    }
    .ldd-circle {
      @include flex($justify-content:center, $align-item:center);
      width: 260px;
      height: auto;
      background-color: $c-red;
      border-radius: 100%;
      @include note_desktop {
        width: 400px;
      }
      img {
        width: auto;
        height: 260px;
        @include note_desktop {
          height: 400px;
        }
      }
    }
  }
  .ldd-about-content {
    @include note_desktop {
      @include flex($justify-content:space-around, $flex-direction:row-reverse);
      margin-top: 40px;
    }
  }
}
.ldd-aboutBook-item {
  margin-top: 20px;
  .ldd-aboutBook-title {
    font-size: 1em;
    color: $c-red;
    font-weight: bold;
    text-transform: uppercase;
  }
  .ldd-aboutBook-paragraph {
    color: $c-gray;
    margin-top: 0;
  }
}

// Section 2 - 
.ldd-content-book {
  background-color: rgba(227, 60, 58, 0.2);
  margin-top: 40px;
  padding: 35px 30px;
  @include note_desktop {
    padding: 60px 40px;
    margin-top: 80px;
  }
  .ldd-title {
    @include note_desktop {
      padding: 0 6em;
      color: $c-red;
    }
  }
  .ldd-about-content {
    @include note_desktop {
      @include flex($justify-content:center, $flex-direction:row-reverse);
      margin-top: 40px;
    }
    .ldd-contentBook-list {
      @include note_desktop {
        display: flex;
        justify-content: center;
        width: 90%;
      }
      .ldd-contentBook-items {
        .ldd-contentBook-item {
          @include flex($justify-content:center, $align-item:center);
          margin-top: 30px;
          @include note_desktop {
            float: left;
            width: 50%;
          }
          .ldd-circle {
            @include flex($justify-content:center, $align-item:center);
            width: 65px;
            height: 65px;
            background-color: $c-red;
            border-radius: 100%;
            @include note_desktop {
              width: 85px;
              height: 85px;
            }
            i {
              font-size: 2.8em;
              color: $c-white;
              @include note_desktop {
                font-size: 3.2em;
              }
            }
            
          }
          .ldd-item-text {
            width: 200px;
            @include note_desktop {
              width: 300px;
            }
            .ldd-paragraph {
              font-size: 0.9em;
              color: black;
              font-weight: bold;
              margin: 0 0 0 15px;
              @include note_desktop {
                font-size: 1em;
              }
            }
          }
        }
      }
    }
  }
}

// Section 3 - 
.ldd-section-aside {
  margin-top: 40px;
  padding: 0 40px;
  @include note_desktop {
    padding: 30px 60px 30px 0;
    width: 400px;
    margin-left: 50px;
  }
  .ldd-title {
    text-align: left;
    width: 80%;
  }
  .ldd-paragraph {
    @include note_desktop {
      margin-top: 20px;
    }
  }
}
.ldd-section-aside.ldd-image-responsive {
  @include note_desktop {
    &:after {
      display: block;
      content: '';
      width: 600px;
      height: 100%;
      background-image: url("../img/img_04.png");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 0;
      left: 33em;
    }
  }
}
.ldd-section-aside-responsive {
  @include note_desktop {
    margin: 0 auto;
    width: 650px;
    text-align: center;
  }
}

// Section 4 - 
.ldd-authors {
  background-color: rgba(227,60,58,.2);
  margin-top: 40px;
  padding: 35px 30px;
  @include note_desktop {
   margin-top: 80px;
   padding: 60px;
  }
  .ldd-title {
    color: $c-red;
  }
  .ldd-authors-items {
    @include note_desktop {
      @include flex($justify-content:center, $wrap:wrap);
    }
    .ldd-authors-item {
      @include flex($flex-direction:column,$justify-content:center, $align-item:center);
      margin-top: 20px;
      .clip-circle {
        clip-path: circle(30% at center);
        width: 315px;
        height: 190px;
        background: $c-white;
      }
      &:first-of-type {
        img {
          transform: scale(0.7);
          top: -30px;
        }
      }
      &:nth-of-type(2) {
        img {
          top: 10px;
          left: 0;
        }
      }
      &:nth-of-type(3) {
        img {
          bottom: 70px;
          left: 0;
          transform: scale(0.5);
        }
      }
      &:nth-of-type(4) {
        img {
          bottom: 70px;
          left: 0;
          transform: scale(0.5);
        }
      }
      &:nth-of-type(5) {
        img {
          bottom: 70px;
          left: 0;
          transform: scale(0.5);
        }
      }
      &:nth-of-type(6) {
        img {
          bottom: 70px;
          left: 0;
          transform: scale(0.5);
        }
      }
      &:nth-of-type(7) {
        img {
          bottom: 70px;
          left: 0;
          transform: scale(0.5);
        }
      }
      &:last-of-type {
        img {
          bottom: 70px;
          left: 0;
          transform: scale(0.5);
        }
      }
      .ldd-authors-name {
        font-size: 1em;
        color:$c-red;
        font-weight: bold;
        text-transform: uppercase;
      }
      .ldd-authors-office {
        font-size: 0.9em;
        color:$c-red;
      }
    }
  }
}

// Section 5 - 
.ldd-about-mjv {
  margin-top: 40px;
  padding: 0 40px;
  @include note_desktop {
    width: 600px;
    text-align: center;
    margin: 80px auto;
  }
  .ldd-title {
    text-align: left;
    width: 80%;
    @include note_desktop {
      width: 100%;
      text-align: center;
    }
  }
}

// Footer
#ldd-footer {
  background-color: $c-black-lead;
  margin-top: 40px;
  padding: 25px 0 5px 0;
  @include note_desktop {
    @include flex($justify-content:space-between);
    padding: 25px 40px;
  }
  .ldd-mjvMidias-items {
    @include flex($justify-content:center, $align-item:center);
    @include note_desktop {
      order: 2;
      width: 223px;
    }
    .ldd-mjvMidias-item {
      .ldd-mjvMidias-link {
        font-size: 1.2em;
        color: $c-white;
        text-decoration: none;
        cursor: pointer;
        margin: 0 5px;
        &:hover {
          color: $c-white;
          text-decoration: none;
        }
        &:focus {
          color: $c-white;
          text-decoration: none;
        }
      }
    }
  }
  .ldd-website-link {
    @include flex($justify-content:center, $align-item:center);
    font-size: 0.8em;
    color: $c-white;
    text-decoration: none;
    margin-top: 5px;
    @include note_desktop {
      order: 3;
      width: 223px;
    }
    &:hover {
      color: $c-white;
      text-decoration: none;
    }
    &:focus {
      color: $c-white;
      text-decoration: none;
    }
  }
  .ldd-copyright {
    @include flex($justify-content:center, $align-item:center);
    margin-top: 10px;
    @include note_desktop {
      order: 1;
      width: 223px;
    }
    i {
      color: $c-gray;
      font-size: 2em;
    }
    .ldd-paragraph {
      font-size: 0.6em;
      color: $c-gray;
      margin: 0 0 0 15px;
    }
  }
}